<template>
  <div class="photo-review">
    <div class="refresh-wrapper">
      <v-refresh v-model="isLoading">
        <v-list
          ref="list"
          :finished="finished"
          :finishedText="finishedText"
          @onLoad="getListData"
        >
          <div
            :class="['header-wrapper', { active: active === true }]"
            v-for="i in configData"
            :key="i.id"
            @click="activeShowClick(i.id)"
          >
            <header class="header">
              <div class="header-word1">{{ i.intime }}</div>
              <div :class="['header-word2', 'pd' + i.auditSts]">
                {{ actAuditStsColumns[i.auditSts] }}
              </div>
            </header>
            <div
              class="main-img"
              v-for="(j, index) in i.imageUrls"
              :key="index"
            >
              <img :src="j" alt="" />
            </div>
          </div>
        </v-list>
      </v-refresh>
    </div>
    <footer class="footer">
      <div v-for="(i, index) in tquScoreColumns" :key="index">
        <div
          :class="['footer-word', { hover: hoverIndex === index }]"
          @click="postReview(index)"
        >
          {{ i }}
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { myHealthUrl, actDataAuditUrl } from "@/views/rubbishSort/api";
import { actAuditStsColumns, tquScoreColumns } from "@/views/rubbishSort/map";
const postStatus = {
  0: "3",
  1: "2",
  2: "1",
};
export default {
  created() {
    const { userId, houseId, id } = this.$route.query;
    this.userId = userId;
    this.houseId = houseId;
    this.id = id;
  },
  data() {
    return {
      configData: [],
      finishedText: "没有更多了",
      curPage: 1,
      pageSize: 20,
      hoverIndex: null,
      userId: "",
      houseId: "",
      id: "",
      dataId: "",
      isLoading: false,
      finished: false,
      active: false,
      actAuditStsColumns,
      tquScoreColumns,
    };
  },
  methods: {
    activeShowClick(id) {
      this.active = !this.active;
      this.dataId = id;
    },
    async postReview(index) {
      this.hoverIndex = index;
      let params = {
        auditSts: postStatus[index],
        userId: this.userId,
        id: +this.dataId,
      };
      if (this.active) {
        const res = await this.$axios({
          method: "post",
          url: actDataAuditUrl,
          params: params,
        });
        if (res.code === 200) {
          this.$router.go(-1);
        }
      } else {
        this.$toast({ message: "请选择一项" });
        this.hoverIndex = null;
      }
    },
    getListData() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        userId: this.userId,
        houseId: this.houseId,
        topicId: this.id,
      };
      this.$axios.get(`${myHealthUrl}`, { params }).then((res) => {
        if (this.curPage >= res.data.current) {
          this.finished = true;
        }
        if (res.data.records.length === 0) {
          this.configData = [];
          this.finishedText = "";
          return;
        }
        if (res.code === 200 && res.data && res.data.records) {
          const list = this.configData.map((i) => i.id);
          res.data.records.forEach((i) => {
            if (i.imageUrls) {
              i.imageUrls = i.imageUrls.split(",");
            }
            list.includes(i.id) || this.configData.push(i);
          });
        }
        this.curPage++;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.photo-review {
  font-family: PingFangSC-Regular, PingFang SC;
  .header-wrapper {
    background: #f9f9f9;
    box-shadow: 0 1px 4px 0 rgba(200, 200, 200, 0.5);
    border-radius: 6px;
    margin: 44px 32px 80px 32px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      .header-word1 {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.85);
        padding-left: 20px;
      }
      .header-word2 {
        font-size: 28px;
        color: #2e5da2;
        padding-right: 24px;
        &.pd0 {
          color: #2e5da2;
        }

        &.pd1 {
          color: #e50112;
        }

        &.pd2 {
          color: #f8cc5c;
        }

        &.pd3 {
          color: #40a739;
        }
      }
    }
    .main-img {
      display: flex;
      flex-wrap: wrap;
      > img {
        width: 172px;
        height: 172px;
        border-radius: 16px;
        margin: 20px 0 30px 20px;
      }
    }
    &.active {
      background: white;
    }
  }
  .footer {
    margin-top: 80px;
    .footer-word {
      text-align: center;
      background: #2e5da2;
      border-radius: 8px;
      height: 80px;
      font-size: 32px;
      color: #ffffff;
      margin: 0 30px;
      margin-bottom: 40px;
      line-height: 80px;
      &.hover {
        background: #ffffff;
        color: #2e5da2;
        border-radius: 8px;
        border: 1px solid #2e5da2;
      }
    }
  }
}
.refresh-wrapper {
  overflow: auto;
  height: 40vh;
}
</style>
