import { mapHelper } from "@/utils/utils.js";

// 	结果
const resultArr = [
  {
    value: 0,
    text: "不合格",
  },
  {
    value: 1,
    text: "不合格",
  },
  {
    value: 2,
    text: "基本合格",
  },
  {
    value: 3,
    text: "合格",
  },
];
const communityIdsColumns = [
  "东兴社区",
  "天宫社区",
  "湾底社区",
  "景湖社区",
  "和丰社区",
  "海创社区",
  "洋江水岸",
  "君睿社区",
  "银桂社区",
];

const villageIdColumns = [
  "东兴小区",
  "蔚蓝水岸小区",
  "东方湾邸",
  "华公馆",
  "文澜水岸",
  "檀宫",
  "涌宁府",
  "天宫庄园",
];
const buildingDataColumns = [
  "1栋",
  "2栋",
  "3栋",
  "4栋",
  "5栋",
  "6栋",
  "7栋",
  "8栋",
];

const isSignPromiseColumns = ["已签", "未签"];

const actAuditStsColumns = ["待审核", "不合格", "基本合格", "合格"];

const tquScoreColumns = ["合格", "基本合格", "不合格"];

const { map: resultArrMap, setOps: resultArrOps } = mapHelper.setMap(resultArr);
// 	拍照列表
const actDataListArr = [
  {
    value: 0,
    text: "未审核",
  },
  {
    value: 1,
    text: "不合格",
  },
  {
    value: 2,
    text: "基本合格",
  },
  {
    value: 3,
    text: "合格",
  },
];
const { map: actDataListArrMap, setOps: actDataListArrOps } =
  mapHelper.setMap(actDataListArr);

// 	拍照列表样式类
const actDataClassArr = [
  {
    value: 0,
    text: "caraStatus0",
  },
  {
    value: 1,
    text: "caraStatus1",
  },
  {
    value: 2,
    text: "caraStatus2",
  },
  {
    value: 3,
    text: "caraStatus3",
  },
];

const isSignPromiseObj = {
  0: "未审核",
  1: "审核通过",
  2: "审核不通过",
};

const evaluateStatus = {
  0: "待评定",
  1: "不合格",
  2: "基本合格",
  3: "合格",
};

const configData = [
  {
    name: "所属社区",
    id: 1,
    value: "",
    statusName: "communityId",
    status: null,
    show: false,
    columns: communityIdsColumns,
  },
  {
    id: 2,
    name: "所属小区",
    value: "",
    statusName: "villageIds",
    status: null,
    show: false,
    columns: villageIdColumns,
  },
  {
    id: 3,
    name: "所属楼栋",
    value: "",
    statusName: "buildingIds",
    status: null,
    show: false,
    columns: buildingDataColumns,
  },
  {
    id: 4,
    name: "是否已签承诺书",
    value: "",
    statusName: "isSignPromise",
    status: null,
    show: false,
    columns: isSignPromiseColumns,
  },
  {
    id: 5,
    name: "照片审核",
    value: "",
    statusName: "actAuditSts",
    status: null,
    show: false,
    columns: actAuditStsColumns,
  },
  {
    id: 6,
    name: "答题得分",
    value: "",
    statusName: "quScore",
    status: null,
    show: false,
    columns: actAuditStsColumns,
  },
  {
    id: 7,
    name: "合格等级",
    value: "",
    statusName: "allScore",
    status: null,
    show: false,
    columns: actAuditStsColumns,
  },
];

const { map: actDataClassArrMap, setOps: actDataClassArrOps } =
  mapHelper.setMap(actDataClassArr);
export {
  resultArr,
  resultArrMap,
  actDataListArr,
  actDataClassArrMap,
  actDataClassArr,
  actDataListArrMap,
  communityIdsColumns,
  villageIdColumns,
  isSignPromiseObj,
  evaluateStatus,
  buildingDataColumns,
  isSignPromiseColumns,
  actAuditStsColumns,
  tquScoreColumns,
  configData,
};
