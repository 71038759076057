let origin = "https://future.zhuneng.cn";
//发表垃圾分类拍照
const postRubUrl = `/serveApi/miniapi/garbage/submitActData`;
//我的运动打卡
const myHealthUrl = `/serveApi/miniapi/garbage/actDataList`;
//运动打卡详情
const healthDetailUrl = `/placeApi/front/healthRecord/detailInfo`;
//社区共建墙
const wishWallUrl = `/placeApi/front/communityConstruction/list`;
//拍照数据列表
const cameraListUrl = `/miniapi/garbage/actDataList`;
//问卷题目
const questionListUrl = `/serveApi/miniapi/garbage/questionList`;
//垃圾分类活动主题详情
const rubTopicInfoUrl = `/serveApi/miniapi/garbage/topicInfo`;
//签名上传
const signImgUrl = `/serveApi/miniapi/garbage/submitSignData`;
//提交问卷
const submitQuestionUrl = `/serveApi/miniapi/garbage/submitQuestionnaire`;
//我最近的答题记录
const myLastAnswersUrl = `/serveApi/miniapi/garbage/myLastAnswers`;
//更新视频
const updateVideoUrl = `/serveApi/miniapi/garbage/updateStudySchedule`;
//统一数据
const allStsDataListUrl = `/serveApi/miniapi/garbage/allStsDataList`;
//我的答题记录
const myQuListUrl = `/serveApi/miniapi/garbage/myQuList`;
//拍照数组审核
const actDataAuditUrl = "/serveApi/miniapi/garbage/actDataAudit";
//答题记录详情
const recordDetailUrl = `/serveApi/miniapi/garbage/myQuInfo`;
export {
  updateVideoUrl,
  submitQuestionUrl,
  postRubUrl,
  myHealthUrl,
  healthDetailUrl,
  wishWallUrl,
  questionListUrl,
  cameraListUrl,
  rubTopicInfoUrl,
  signImgUrl,
  myLastAnswersUrl,
  allStsDataListUrl,
  myQuListUrl,
  actDataAuditUrl,
  recordDetailUrl,
};
